<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="800"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <!-- Radio Buttons Row -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.name"
                  label="Name"
                  :rules="[(v) => !!v || 'Name required !!']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autocomplete="off"
                  type="text"
                  outlined
                  dense
                  v-model="editedItem.description"
                  label="Description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.interface"
                  :items="interfaces"
                  label="Interface"
                  :rules="[(v) => !!v || 'Interface required']"
                  item-value="id"
                  item-text="name"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.model"
                  :items="modeles"
                  item-value="id"
                  item-text="name"
                  label="model"
                  :rules="[(v) => !!v || 'Model required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.event_type"
                  :items="event_types"
                  item-value="id"
                  item-text="name"
                  label="Type"
                  :rules="[(v) => !!v || 'Type required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.status_ini"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  label="Initial Status"
                  :rules="[(v) => !!v || 'Initial Status required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.status_yes"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  label="Next Status"
                  :rules="[(v) => !!v || 'Next Status required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.status_no"
                  :items="status"
                  item-value="id"
                  item-text="name"
                  label="Rejected Status"
                  :rules="[(v) => !!v || 'Rejected Status required']"
                  chips
                  dense
                  v-if="editedItem.event_type == 1"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.complete_mode"
                  :items="modes"
                  item-value="id"
                  item-text="name"
                  label="Complete Mode"
                  :rules="[(v) => !!v || 'Complete Mode required']"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-checkbox
                  label="Active"
                  v-model="editedItem.active"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-autocomplete
                  v-model="selected_auths"
                  :items="list_auths"
                  :item-text="'autorisation'"
                  :item-value="'code'"
                  chips
                  deletable-chips
                  multiple
                  label="Autorisations"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index <= 2">
                      <span>{{ item.autorisation }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text text-caption">
                      (+{{ selected_auths.length - 3 }} autres)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_EVENT from "../graphql/Event/CREATE_EVENT_APP.gql";
import UPDATE_EVENT from "../graphql/Event/UPDATE_EVENT_APP.gql";
import { autorisations } from "@/utils/functions.js";

export default {
  components: {},
  name: "eventform",
  props: {
    item: Object,
    items: Array,
    event: Object,
    showForm: Boolean,
    auths: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    selected_auths: [],
    list_auths: [],
    interfaces: [
      { id: "requests", name: "Requests" },
      { id: "proformas", name: "Proformas" },
      { id: "commandes_c", name: "Commandes Clients" },
      { id: "commandes_f", name: "Commandes Fournisseurs" },
      { id: "product_tobuy", name: "Produits à vendre" },
    ],
    modeles: [
      { id: "Request", name: "Requests" },
      { id: "Proforma", name: "Proformas" },
      { id: "Commande", name: "Commandes" },
      { id: "Product", name: "Produits" },
    ],
    event_types: [
      { id: "0", name: "Task Completion" },
      { id: "1", name: "Task Approval" },
      { id: "2", name: "Task Attribution" },
    ],
    event_status: [
      {
        id: "Request",
        status: [
          { id: "0", name: "Pending" },
          { id: "1", name: "In progress" },
          { id: "2", name: "Completed" },
          { id: "3", name: "Rejected" },
          { id: "4", name: "Accepted" },
          { id: "5", name: "Canceled" },
        ],
      },
      {
        id: "Proforma",
        status: [
          { id: "1", name: "Brouillon" },
          { id: "2", name: "Proforma" },
          { id: "3", name: "Annulée" },
          { id: "4", name: "Confirmée" },
        ],
      },
      {
        id: "Commande",
        status: [
          { id: "6", name: "Brouillon" },
          { id: "7", name: "Confirmée" },
          { id: "8", name: "Archive" },
          { id: "9", name: "Soldée Man." },
        ],
      },
    ],
    modes: [
      { id: "one", name: "One" },
      { id: "all", name: "All" },
    ],
  }),

  computed: {
    status() {
      let l;
      if (this.editedItem)
        if (this.editedItem.model) {
          let i = this.event_status.findIndex(
            (s) => s.id == this.editedItem.model
          );
          if (i >= 0) l = this.event_status[i].status;
        }
      return l;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "New Event "
          : "Event : ".concat(this.item.name);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.list_auths = autorisations;
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.selected_auths = this.editedItem.autorisations.split(",");
      } else this.editedItem = Object.assign({}, { id: -1 });
    }
  },

  methods: {
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > 0) {
          let r = await this.maj(UPDATE_EVENT, {
            eventapp: {
              id: this.editedItem.id,
              name: this.editedItem.name,
              description: this.editedItem.description,
              interface: this.editedItem.interface,
              model: this.editedItem.model,
              event_type: this.editedItem.event_type,
              status_ini: this.editedItem.status_ini,
              status_yes: this.editedItem.status_yes,
              status_no: this.editedItem.status_no,
              complete_mode: this.editedItem.complete_mode,
              active: this.editedItem.active ? 1 : 0,
              autorisations: this.selected_auths.toString(),

              write_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.splice(this.item.index, 1, this.editedItem);
          }
        } else {
          let r = await this.maj(CREATE_EVENT, {
            eventapp: {
              name: this.editedItem.name,
              description: this.editedItem.description,
              interface: this.editedItem.interface,
              model: this.editedItem.model,
              event_type: this.editedItem.event_type,
              status_ini: this.editedItem.status_ini,
              status_yes: this.editedItem.status_yes,
              status_no: this.editedItem.status_no,
              complete_mode: this.editedItem.complete_mode,
              active: this.editedItem.active ? 1 : 0,
              autorisations: this.selected_auths.toString(),
              create_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.push(this.editedItem);
          }
        }
        this.$emit("changed");
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
